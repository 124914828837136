<template>
  <div id="phtoAndForm">
    <!-- 表單 -->
    <ProfileForm
      ref="profileForm"
      :formType="decideCaseStatusInfo.warningFormType"
      :data="profileForm"
      :status="componentStatus.profileForm"
      :uploadImgFlag="uploadImgFlag"
      @handleImgUpload="handleUploadImg"      
      :tabNewsStatus="componentStatus.tabNews"
      :TabNewsFlag="TabNewsFlag"
      :newsDataID="propsDataID"
      @handleNewsData="handleNewsData"
      :attachmentStatus="componentStatus.attachment"
      :attachmentData = "filesId"
      :uploadFileFlag="uploadFileFlag" 
      @formData="handleFormData"
      :approvalRecordStatus="componentStatus.approvalRecord"
      :role="decideCaseStatusInfo.userRole"
      :approvalRecordData="propSuggestion"
      :tListID = "tList"      
    ></ProfileForm>

    <!-- 關聯新聞 
    <TabNews
      v-if="componentStatus.tabNews"
      :status="componentStatus.tabNews"
      :TabNewsFlag="TabNewsFlag"
      :newsDataID="propsDataID"
      @handleNewsData="handleNewsData"
    />
-->
    <!-- 附件上傳 
    <Attachment
      v-if="componentStatus.attachment"
      ref="attachmentForm"
      :data="filesId"
      :uploadFileFlag="uploadFileFlag" 
      :status="componentStatus.attachment"
      @formData="handleFormData"
    ></Attachment>
-->

    <!-- 簽核歷程和意見 
    <ApprovalRecord
      v-if="componentStatus.approvalRecord"
      ref="approvalRecordForm" 
      :caseStatus="componentStatus.approvalRecord"
      :role="decideCaseStatusInfo.userRole"
      :data="propSuggestion"
      :tListID = "tList"
    ></ApprovalRecord>
-->
    <!-- 按鈕 -->
    <WarningFormBtn
      :status="componentStatus.warningFormBtn"
      @doFunction="handleSubmit"
    ></WarningFormBtn>
  </div>

</template>

<script>
import ProfileForm from "../../components/ProfileForm.vue";
import TabNews from "../../components/Tabnews.vue";
import Attachment from "../../components/Attachment.vue";
import WarningFormBtn from "../../components/WarningFormBtn.vue";
import ApprovalRecord from "../../components/ApprovalRecord.vue";
export default {
  components: { ProfileForm, TabNews, Attachment, WarningFormBtn,ApprovalRecord},
  data() {
    return {
      propSuggestion:"",
      profileForm:{},
      filesId:[],
      propsDataID:{},
      submitType:"",
      temp:{},//送表單的物件
      TabNewsFlag:false,
      uploadFileFlag:false,
      uploadImgFlag:false,
      tList : this.$route.query.listID,
      attachmentRequestData:{
        UserID:"",
        ListID:"",
        Attachement:[]
      },
      suggestion:{},
      requestIdInfo:{
        Type:this.$route.query.warningFormType,//警示人名或組織
        UserID:this.$store.state.userInfo.UserID,//登入者的ID
        ListID:this.$route.query.listID,//新增或編輯表單需要的參數
      },
      decideCaseStatusInfo:{
        caseStatus: this.$route.query.caseStatus,
        permission:this.$route.query.permission,
        warningFormType: this.$route.query.warningFormType,
        userRole:this.$store.state.userInfo.Role,
      },
      componentStatus:{
        profileForm:"",
        tabNews:"",
        attachment:"",
        warningFormBtn:"",
        approvalRecord:""
      },
      newsListQuery: {
        DataID:[],
        Page_Size: 999, //每頁有幾筆
        Page_Num: 1, //目前頁數
      },
    };
  },
  methods: {
    /*業務邏輯 */
    handleCaseStatus(){
      let {caseStatus,userRole,permission} = {...this.decideCaseStatusInfo}
      //承辦人新增表單
      if(caseStatus==='add'){
        this.componentStatus.profileForm = 'addStatus'
        this.componentStatus.tabNews = 'addStatus'
        this.componentStatus.attachment = 'addStatus'
        this.componentStatus.warningFormBtn = 'saveAndWaitApproval'
        this.componentStatus.approvalRecord = this.$route.query.caseStatus
      }
      if(caseStatus==='edit'){
        if((userRole==='operator' && permission==='False')||(userRole==='manager')){
          this.componentStatus.profileForm = 'previewStatus'
          this.componentStatus.tabNews = 'previewStatus'
          this.componentStatus.attachment = 'previewStatus'
          this.componentStatus.warningFormBtn = 'cancel'
          this.componentStatus.approvalRecord = this.$route.query.caseStatus
        }
        if(userRole==='operator' && permission==='True'){
          this.componentStatus.profileForm = 'editStatus'
          this.componentStatus.tabNews = 'editStatus'
          this.componentStatus.attachment = 'editStatus'
          this.componentStatus.warningFormBtn = 'saveAndWaitApproval'
          this.componentStatus.approvalRecord = this.$route.query.caseStatus
        }
      }
      if(caseStatus === 'wait_approval'||caseStatus === 'wait_delete'){
        this.componentStatus.profileForm = 'previewStatus'
        this.componentStatus.tabNews = 'previewStatus'
        this.componentStatus.attachment = 'previewStatus'
        this.componentStatus.approvalRecord = this.$route.query.caseStatus
        if(userRole==='manager'){
          if(caseStatus === 'wait_approval'){
            this.componentStatus.warningFormBtn = 'approvalAndReject'
          }else if(caseStatus === 'wait_delete'){
            this.componentStatus.warningFormBtn = 'approvalDeleteAndReject'
          }
        }
        if(userRole==='operator'){
          this.componentStatus.warningFormBtn = 'cancel'
        }
      }
      if(caseStatus==='approval'||caseStatus==='reject'){
        this.componentStatus.profileForm = 'previewStatus'
        this.componentStatus.tabNews = 'previewStatus'
        this.componentStatus.attachment = 'previewStatus'
        this.componentStatus.approvalRecord = this.$route.query.caseStatus
        if(userRole==='manager'){
          this.componentStatus.warningFormBtn = 'cancel'
        }else if(userRole==='operator'){
          this.componentStatus.warningFormBtn = 'reviseAndDelete'
        }
      }
      if(caseStatus==='delete'){
        this.componentStatus.profileForm = 'previewStatus'
        this.componentStatus.tabNews = 'previewStatus'
        this.componentStatus.attachment = 'previewStatus'
        this.componentStatus.approvalRecord = this.$route.query.caseStatus
         this.componentStatus.warningFormBtn = 'cancel'
      }
    },
    async handleSubmit(submitType){

      //console.log("Btn 行為",submitType);
      this.submitType = submitType
      if(submitType==='back'){
        this.$router.go(-1)
      }else if(submitType==='save'||submitType==='waitApproval'){
        // console.log("Valid", this.$refs['profileForm']);
        const validResult = this.$refs['profileForm'].validForm();
        console.log("validResult",validResult);
        if (validResult){
          console.log("btn 檢查成功",this.requestIdInfo.ListID);
          this.$store.dispatch("loadingHandler", true);
          if(!this.requestIdInfo.ListID){
            /*取得ListID*/
            let {UserID,Type} = this.requestIdInfo;
            this.requestIdInfo.ListID = await this.getListID(UserID,Type);
            console.log("Save", this.requestIdInfo);
            this.handleRequestData()
          }else{
            this.handleRequestData()
          }
          this.$store.dispatch("loadingHandler", false);
        } 
        else
        {          
          this.$message({
            message: '表單未填完整',
            type: 'warning'
          });
        }
      }else if(submitType==='approval'||submitType==='reject'||submitType==='delete'){
        console.log('approval');
        // this.suggestion = JSON.parse(JSON.stringify(this.$refs['approvalRecordForm'].suggestion))
        this.suggestion = JSON.parse(JSON.stringify(this.$refs['profileForm'].suggestion))
        this.doChangeCaseStatus(submitType)

      }else if(submitType==='revise' ){

        await this.doGetInfo(this.requestIdInfo.ListID,'editinfo')
        this.componentStatus.profileForm = 'editStatus'
        this.componentStatus.tabNews = 'editStatus'
        this.componentStatus.attachment = 'editStatus'
        this.componentStatus.warningFormBtn = 'saveAndWaitApproval'
        this.componentStatus.approvalRecord = 'edit'
      } else if( submitType=='abandon') {
        //alert('Abandon, 要叫... /bid/aim/list/abandon');
        console.log("放棄..",this.requestIdInfo.ListID);
        await this.doAbandom(this.requestIdInfo.ListID);
        this.$store.dispatch("loadingHandler", false);
        this.$router.go(-1);       
      } else if( submitType==='waitDelete') {
          
          let {ListID,UserID,Type} = this.requestIdInfo;
          console.log(this.requestIdInfo);
          let qry = {
            UserID:UserID,
            Data: [ {ListID: ListID,Memo:""} ]
          }
          await this.$api.list.waitDelete(qry).then(r=>{
              this.$notify({
                title: "成功",
                message: "刪除成功!",
                type: "success",
              });
            }
          ).catch(err=>{
            this.$notify({
              title: "錯誤",
              message: msg,
              type: "warning",
            });
          }

          );
          this.$store.dispatch("loadingHandler", false);
          this.$router.go(-1)
          
      }
      //console.log('func end');
      this.$store.dispatch("loadingHandler", false);
    },
    handleRequestData(){
      this.uploadImgFlag = true;//處理照片上傳
      this.TabNewsFlag = true;//處理關聯新聞資料
      this.uploadFileFlag = true;//處理上傳附件
      this.handleInputForm()//處理表單資料
    },
    handleInputForm(){
      let inputForm = JSON.parse(JSON.stringify(this.$refs['profileForm'].postForm))
      //console.log("Suggestion",this.$refs['profileForm'].suggestion);
      
      //this.suggestion = JSON.parse(JSON.stringify(this.$refs['approvalRecordForm'].suggestion))
      //0607, 改成讀profileForm的變數...
      this.suggestion = JSON.parse(JSON.stringify(this.$refs['profileForm'].suggestion))
      /*處理多筆,把多筆是空值的刪掉*/
        //國籍
      inputForm.Nation.forEach((item,index)=>{
        if(!item.Nation){
          inputForm.Nation.splice(index,1)
        }
      })
        //翻譯名
      inputForm.TransName.forEach((item,index)=>{
        if(!item.TransName){
          inputForm.TransName.splice(index,1)
        }
      })
        //其他稱呼//公司簡稱
      inputForm.OtherName.forEach((item,index)=>{
        if(!item.OtherName){
          inputForm.OtherName.splice(index,1)
        }
      })
        //就職紀錄
      inputForm.Work.forEach((item,index)=>{
        if(!item.Occupation){
          delete item.Occupation
        }
        if(!item.Title){
          delete item.Title
        }
        if(!item.ArrivedDt){
          delete item.ArrivedDt
        }
        if(!item.LeavedDt){
          delete item.LeavedDt
        }
        if(!item.Occupation && !item.Title && !item.ArrivedDt && !item.LeavedDt){
          inputForm.Work.splice(index,1)
        }
      })
        //變更紀錄
      inputForm.OrgRecord.forEach((item,index)=>{
        if(!item.ChangeDt){
          delete item.ChangeDt
        }
        if(!item.ChangeItem){
          delete item.ChangeItem
        }
        if(!item.ChangeDt && !item.ChangeItem){
          inputForm.OrgRecord.splice(index,1)
        }
      })
      
        //經營產業
      inputForm.OrgIndus.forEach((item,index)=>{
        if(!item.Indus){
          inputForm.OrgIndus.splice(index,1)
        }
      })
        //聯絡方式
      inputForm.Contact.forEach((item,index)=>{
        if(!item.Contact){
          inputForm.Contact.splice(index,1)
        }
      })
        //居住地
      inputForm.Address.forEach((item,index)=>{
        if(!item.Place){
          inputForm.Address.splice(index,1)
        }
      })
        //關聯資訊
      inputForm.Relation.forEach((item,index)=>{
        if(!item.RelEntity){
          delete item.RelEntity
        }
        if(!item.RelMemo){
          delete item.RelMemo
        }
        if(!item.RelEntity && !item.RelMemo){
          inputForm.Relation.splice(index,1)
        }
      })
      this.temp = JSON.parse(JSON.stringify(inputForm));
      console.log("Save", this.temp);
    },
    handleNewsData(newsData){
      console.log('處理新聞');
      let existingNewsKeys = Object.keys(newsData);
      existingNewsKeys.forEach((key)=>{
        switch(key){
          case 'searchNews':
            this.temp.News = newsData[key];
            break
          case 'searChsan':
            this.temp.Sanction = newsData[key];
            break
          case 'searchConstr':
            this.temp.Constr = newsData[key];
            break
          case 'searchLabor':
            this.temp.Labor = newsData[key];
            break
          case 'searchEnviroment':
            this.temp.Enviroment = newsData[key];
            break
        }
      })
      this.TabNewsFlag = false;
      this.doSubmit();
    },
    //因為是多筆檔案,所以每次承辦人儲存或送審時組件一定會$emit出來
    async handleFormData(fileAry){
      console.log(fileAry);
      let frontendIDs = 0
      let hasFiles = fileAry.filter((item)=>!!item.file)
      let requestData = {
        UserID:"",
        ListID:"",
        Attachement:[]
      }
   
      
      if(hasFiles.length!==0){
        fileAry.forEach((item)=>{
          item.frontendID = frontendIDs
          frontendIDs++
        })
        let fd = new FormData();
        let requestFilesIDs = []
        console.log(fileAry);
        fileAry.forEach((item)=>{
          if(!!item.file){
            console.log(item.file);
            fd.append('fileInfo[]',item.file)
            requestFilesIDs.push(item.frontendID)
          }
        })
        let requestFilesIDsString = requestFilesIDs.join()
        console.log(typeof requestFilesIDsString);
        fd.append('frontendID',requestFilesIDsString)
        await this.$api.list.uploadFile(fd).then((res)=>{
          fileAry.forEach((item)=>{
            res.Files.forEach((el)=>{
              if(String(item.frontendID)===el.frontendID){
                item.AttName = el.AttName;
                item.AttUrl = el.AttUrl;
              }
            })
          })
          fileAry.forEach((item)=>{
            delete item.file
            delete item.frontendID
          })
          requestData.Attachement = fileAry;
          console.log(requestData);
          this.doUploadAttachment(requestData)    
        })
      }else{
        fileAry.forEach((item)=>{
          delete item.file
        })
        requestData.Attachement = fileAry;
        console.log(requestData.Attachement);
        this.doUploadAttachment(requestData) 
      }
      this.uploadFileFlag = false;
    },
    doUploadAttachment(requestData){
      return new Promise((resolve)=>{
        let {ListID,UserID} = this.requestIdInfo;
        requestData.UserID = UserID;
        requestData.ListID = ListID;
        requestData.IsUser = true;
        this.$api.list.uploadAttachement(requestData).then((res) => {
          resolve(res)
        });
      })
    },
    //因為是單筆檔案,如果組件沒有新增或更新照片檔案,組件就不會$emit
    async handleUploadImg(imgFile){
      let {UserID,Type} = this.requestIdInfo
      if(!!this.requestIdInfo.ListID){
        this.doUploadImage(imgFile);
      }else{
        await this.getListID(UserID,Type)
        await this.doUploadImage(imgFile);
      }
    },
    doUploadImage(formData) {
      return new Promise((resolve) => {
        let {UserID,ListID} = this.requestIdInfo
        formData.append("UserID",UserID);
        formData.append("ListID",ListID);
        formData.append("IsUser",true);
        this.$api.list.uploadImg(formData).then((res) => {
          resolve(res);
        });
      });
    },
    getListID(UserID,Type) {
      return new Promise((resolve) => {
        this.$api.list.addList({UserID:UserID,Type:Type,IsUser:true}).then((res) => {
          resolve(res.ListID);
        });
      });
    },
    doAbandom(listID) {
      return new Promise((resolve) => {
        this.$api.list.abandon({ListID:listID}).then((res) => {
          resolve(res);
        });
      });
    },
    doSetInfo(data){
      return new Promise((resolve) => {
        delete data.Base.PicBase64;
        delete data.Base.PicUrl;
        /*處理送審備註(承辦)或覆核意見(管理者)*/
        let role = this.$store.state.userInfo.Role
        if(role === "operator"){
          this.temp.Memo = this.suggestion.operator
        }else if(role === "manager"){
          this.temp.Memo = this.suggestion.manager
        }
        //添加使用者紀錄的參數
        data.IsUser = true;

        this.$api.list.setInfo(data).then((res) => {
          resolve(res);
        });
      });
    },
    doGetInfo(ListID,type){
      //console.log(type);
      this.$store.dispatch("loadingHandler", true);
      this.$api.list[type]({ListID:ListID}).then((res) => {
        //prop附件給組件
        if(res.Attachement.length>0){
          this.filesId = JSON.parse(JSON.stringify(res.Attachement))
        }
        //prop送審備註或審核意見給組件
        this.propSuggestion = res.Memo;  

        //prop表單資料給組件
        let {Base,Attribute,Nation,TransName,OtherName,Work,OrgRecord,OrgIndus,Contact,Address,Relation} = {...res}
        let handleProfileForm = {};
        handleProfileForm.Base = Base
        handleProfileForm.Attribute = Attribute
        //console.log("handleProfileForm.Attribut",Attribute);
        handleProfileForm.Nation = Nation
        handleProfileForm.TransName = TransName
        handleProfileForm.OtherName = OtherName
        handleProfileForm.Work = Work
        handleProfileForm.OrgRecord = OrgRecord
        handleProfileForm.OrgIndus = OrgIndus
        handleProfileForm.Contact = Contact
        handleProfileForm.Address = Address
        handleProfileForm.Relation = Relation
        if(handleProfileForm.Nation.length===0){
          handleProfileForm.Nation.push({
            ID: "",
            Nation: "",
          })
        }
        if(handleProfileForm.TransName.length===0){
          handleProfileForm.TransName.push({
            ID: "",
            TransName: "",
          })
        }
        if(handleProfileForm.OtherName.length===0){
          handleProfileForm.OtherName.push({
            ID: "",
            OtherName:''
          })
        }
        if(handleProfileForm.Work.length===0){
          handleProfileForm.Work.push({
            ID: "",
            Occupation: "",
            Title: "",
            ArrivedDt: "",
            LeavedDt: "",
          })
        }
        if(handleProfileForm.Contact.length===0){
          handleProfileForm.Contact.push({
            ID: "",
            Contact: "",
          })
        }
        if(handleProfileForm.Relation.length===0){
          handleProfileForm.Relation.push({
            ID: "",
            RelEntity: "",
            RelMemo: "",
          })
        }
        if(handleProfileForm.Address.length===0){
          handleProfileForm.Address.push({
            ID: "",
            Place: "",
          })
        }
        if(handleProfileForm.OrgRecord.length===0){
          handleProfileForm.OrgRecord.push({
            ID: "",
            ChangeDt: "",
            ChangeItem: "",
          })
        }
        if(handleProfileForm.OrgIndus.length===0){
          handleProfileForm.OrgIndus.push({
            ID: "",
            Indus: "",
          })
        }
        this.profileForm = JSON.parse(JSON.stringify(handleProfileForm))
        
        //prop關聯新聞給組件
        let {News,Constr,Enviroment,Labor,Sanction} = {...res}
        let allNewsDataID = {};
        if(News.length!==0){
          allNewsDataID.News = News
        }
        if(Constr.length!==0){
          allNewsDataID.Constr = Constr
        }
        if(Enviroment.length!==0){
          allNewsDataID.Enviroment = Enviroment
        }
        if(Labor.length!==0){
          allNewsDataID.Labor = Labor
        }
        if(Sanction.length!==0){
          allNewsDataID.Sanction = Sanction
        }
        this.propsDataID = allNewsDataID
        this.$store.dispatch("loadingHandler", false);
      });
      
    },
    async doSubmit(){
      let {ListID,UserID} = this.requestIdInfo;
      
      /*儲存api*/
      this.temp.ListID = ListID;
      this.temp.UserID = UserID;
      this.temp.IsUser = true;
      let result = await this.doSetInfo(this.temp)
      if(this.submitType==='save' && result==='Success'){
        console.log('setInfo成功');
        this.$message({
          message: '儲存成功',
          type: 'success'
        });
        this.$store.dispatch("loadingHandler", false);
        this.$router.go(-1)
      }

      /*送審api*/
      if(this.submitType==='waitApproval'){
        this.doChangeCaseStatus('waitApproval')
      }
    },
    doChangeCaseStatus(btnType){
      return new Promise((resolve) => {
        this.$store.dispatch("loadingHandler", true);
        let requestData = {
          UserID:this.requestIdInfo.UserID,
          IsUser:true,
          Data:[{ListID:this.requestIdInfo.ListID}]
        }
        if(btnType==='waitApproval' && this.suggestion.operator){
          requestData.Data[0].Memo = this.suggestion.operator
        }
        if(btnType==='approval' || btnType==='reject'){
          if(!!this.suggestion.manager){
            requestData.Data[0].Memo = this.suggestion.manager
          }
        }
      
        this.$api.list[btnType](requestData).then((res) => {
          if(res==='Success'){
            this.$message({
              message: '成功',
              type: 'success'
            });
          }
          this.$store.dispatch("loadingHandler", false);
          this.$router.go(-1)
          resolve();
        });
      });
    }
  },
  mounted() {
    this.handleCaseStatus();
    if(this.componentStatus.profileForm==='editStatus'){
      this.doGetInfo(this.requestIdInfo.ListID,'editinfo')
    }else if(this.componentStatus.profileForm==='previewStatus'){
      this.doGetInfo(this.requestIdInfo.ListID,'latestinfo')
    }
    if(this.$route.query.caseStatus==='add'){
      this.propsDataID = JSON.parse(this.$route.query.DataID)
    }
  },
};
</script>

<style lang="scss" scoped>
#phtoAndForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 1024px;
  padding: 3% 5%;
  background: linear-gradient(45deg, #00abb9 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  box-sizing: border-box;
}
</style>
